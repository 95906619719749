import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl, getIndicatorClass } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import BulkUpload from "./upload";
import { Settings } from "../../utils/Settings";
import { ToolFields } from "../../fields/ToolFields";
import { IndicatorFields } from "../../fields/IndicatorFields";
import { MeansOfVerificationFields } from "../../fields/MeansOfVerificationFields";
import { Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import TargetForm from "./TargetForm";
import { XIndicatorCard } from "../../components/common/XIndicatorCard";
import { Loading } from "../../components/common/Loading";

const request = new ApiCore();

const IndicatorDetails = () => {
  const { id } = useParams();
  const [indicator, setIndicator] = useState<any>(null);
  const [windows, setWindows] = useState<any>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);
  const [showMovDialog, setShowMovDialog] = useState<boolean>(false);
  const [showActivityDialog, setShowActivityDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showTargetDialog, setShowTargetDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [initialActivityValues, setInitialActivityValues] = useState<any>({});
  const [initialLogframeValues, setInitialLogframeValues] = useState<any>({});
  const [initialWindowValues, setInitialWindowValues] = useState<any>({});

  useEffect(() => {
    loadIndicator();
    loadPorgramWindows();
  }, []);

  const loadIndicator = () => {
    setLoading(true);
    request
      .get(`${Endpoints.logframe}/indicators/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setIndicator(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const loadPorgramWindows = () => {
    request
      .get(`${Endpoints.logframe}/indicators/${id}/windows`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setWindows(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadIndicator();
  };

  const handleClick = (record: any, action: string) => {
    if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/uploads/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadIndicator();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }
    values.parentId = indicator.id;
    values.parentType = "indicator";

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadIndicator();
    });
  }

  const handleSaveTarget = (values: any) => {
    values.programWindow = { id: values.programWindow }
    toast.promise(request.create(Endpoints.logframe + "/indicators/" + indicator.id, values), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadIndicator();
      loadPorgramWindows();
      setShowTargetDialog(false)
    });
  }

  const windowOptions = () => {
    let options: any = []
    options = windows.map((data: any) => ({
      value: data.id,
      text: data.label,
      data: JSON.stringify(data)
    }))

    return options
  }
  return (
    <React.Fragment>
      {loading && <Container fluid><p>&nbsp;</p><p>&nbsp;</p><br /><Loading label="Please wait.." /></Container>}
      {indicator && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Indicator"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={IndicatorFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.logframe + "/indicators"}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>

            <XModal title={"Add Target"}
              width={"lg"}
              open={showTargetDialog}
              toggle={() => setShowTargetDialog(!showTargetDialog)}
              onClose={() => setShowTargetDialog(false)}>

              <TargetForm initialValues={initialWindowValues} windows={windowOptions()} handleSubmit={handleSaveTarget} />
            </XModal>

            <XModal title={"Add a tool"}
              width={"sm"}
              open={showToolDialog}
              toggle={() => setShowToolDialog(!showToolDialog)}
              onClose={() => setShowToolDialog(false)}>

              <CreateForm
                fields={ToolFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.tools}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeSubmit}
                onClose={() => setShowToolDialog(false)} />

            </XModal>

            <XModal title={"Add Means of Verification"}
              width={"sm"}
              open={showMovDialog}
              toggle={() => setShowMovDialog(!showMovDialog)}
              onClose={() => setShowMovDialog(false)}>

              <CreateForm
                fields={MeansOfVerificationFields}
                initialValues={initialLogframeValues}
                submitEndpoint={Endpoints.logframe + "/movs/" + indicator.id}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowMovDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: indicator.id, entityType: "indicator" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Indicator Details
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(indicator); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => {
                            setShowMovDialog(true);
                          }}>
                            Add Means of Verification
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowTargetDialog(true)}>
                            Add Target
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={8}>
                              <div className="dt-value">{indicator.code} - {indicator.name}</div>
                            </Col>
                            <Col md={2}>
                              <div className="dt-value"><b>TARGET:</b> {indicator.target}</div>
                            </Col>
                          </Row>
                          {indicator.details && indicator.details.length > 0 && <><hr />
                            <Row>
                              <Col md={12}>
                                <div className="dt-value">{indicator.details}</div>
                              </Col>
                            </Row></>}
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <Row>
                    {windows && windows.map((record: any) => {
                      return <Col md={3}><XIndicatorCard record={record} />
                      </Col>
                    })}
                  </Row>
                  <hr />
                  {indicator.meansOfVerifications && indicator.meansOfVerifications.length > 0 &&
                    <Card>
                      <CardBody>
                        <b>MEANS OF VERIFICATION</b><br />
                        <table className="table bordered">
                          <tbody>
                            {indicator.meansOfVerifications.map((record: any) => {
                              return <tr key={record.id + '_upload'}>
                                <td className="txt-right">{record.code} - <a href={`/logframe/movs/${record.id}`}>{record.name}</a></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>}
                </Col>
                <Col>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Documents
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowUploadDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th></th></tr>
                        </thead>
                        <tbody>
                          {indicator.uploads.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={Settings.getFile(record.path, "download")}>{record.name && record.name.length > 0 ? record.name : record.path}</a></td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_upload")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Tools
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowToolDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th></tr>
                        </thead>
                        <tbody>
                          {indicator.tools && indicator.tools.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/tools/${record.id}`}>{record.name}</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default IndicatorDetails;
