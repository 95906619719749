import { Field } from "formik";
import { Col, Label } from "reactstrap";
import React from "react";
import { ITextAreaProps } from "../../../interfaces/inputs/ITextAreaProps";

export const XTextArea = (props: ITextAreaProps) => {

  const isTouched = props.touched && props.touched[props.name];
  const hasErrors = props.errors && props.errors[props.name];

  return (
    <Col {...props.size}>
      <div className={`mb-3 ${props.icon && "form-floating form-floating-custom"}`}>

        {!props.icon && props.label && props.label.length > 0 &&
          <Label className={hasErrors && "text-danger"}
                 htmlFor={props.id}>{props.label}</Label>
        }

        <Field name={props.name} as="textarea"
               className={`form-control no-resize ${hasErrors && "is-invalid"}`}
               rows={props.rows}
               id={props.id}
               placeholder={props.placeholder} />

        {hasErrors && <small className={"text-danger"}>
          {props.errors[props.name]}
        </small>}

        {props.icon &&
          <>
            <Label className={hasErrors && "text-danger"}
                   htmlFor={props.id}>{props.label}</Label>
            <div className="form-floating-icon">
              <i className={`fa ${props.icon} ${hasErrors && "text-danger"}`}></i>
            </div>
          </>
        }
      </div>
    </Col>
  );
};