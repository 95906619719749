import { Form, Formik } from "formik";
import { IFormProps } from "../../interfaces/IFormProps";
import { Button, Col, Row, Spinner } from "reactstrap";
import { XTextBox } from "../common/inputs/XTextBox";
import { XTextArea } from "../common/inputs/XTextArea";
import { XSelect } from "../common/inputs/XSelect";
import { XCheckbox } from "../common/inputs/XCheckbox";
import { ApiCore } from "../../helpers/api_helper";
import { AxiosError, AxiosResponse } from "axios";
import { XFileInput } from "../common/inputs/XFileInput";
import { XDatePickerField } from "../common/inputs/XDatePickerField";

const request = new ApiCore();

const CreateForm = (props: IFormProps) => {

  const {
    initialValues,
    validationSchema,
    handleUpdate,
    handleSubmit,
    handleBeforeSubmit,
    onClose,
    fields,
    submitEndpoint,
    formData,
    debug
  } = props

  const onSubmit = (values: any) => {
    if (handleSubmit) {
      handleSubmit(values);
    } else {
      if (handleBeforeSubmit) {
        values = handleBeforeSubmit(values);
      }
      if (submitEndpoint) {
        if (values.id) {
          if (formData) {
            request.updateFormData(submitEndpoint, values)
              .then((response: AxiosResponse) => {

                if (handleUpdate) handleUpdate(response.data);
                if (onClose) onClose()

              }).catch((error: AxiosError) => {
                console.log(error)
              })
          } else {
            request.update(submitEndpoint, values)
              .then((response: AxiosResponse) => {

                if (handleUpdate) handleUpdate(response.data);
                if (onClose) onClose()

              }).catch((error: AxiosError) => {
                console.log(error)
              })
          }
        } else {
          if (formData) {
            request.createFormData(submitEndpoint, values)
              .then((response: AxiosResponse) => {

                if (handleUpdate) handleUpdate(response.data);
                if (onClose) onClose()

              }).catch((error: AxiosError) => {
                console.log(error)
              })
          } else {
            request.create(submitEndpoint, values)
              .then((response: AxiosResponse) => {

                if (handleUpdate) handleUpdate(response.data);
                if (onClose) onClose()

              }).catch((error: AxiosError) => {
                console.log(error)
              })
          }
        }
      }
    }

  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      onSubmit={(values: any) => onSubmit(values)}>

      {({ isSubmitting, values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="modal-body">
            <Row>
              {fields.map((field: any) => {
                if (field.type === "select") {
                  return <XSelect key={field.name} id={field.name} {...field} touched={touched} errors={errors} />;
                } else if (field.type === "textarea") {
                  return <XTextArea key={field.name} id={field.name} {...field} touched={touched} errors={errors} />;
                } else if (field.type === "checkbox") {
                  return <XCheckbox key={field.name} id={field.name} {...field} onChange={setFieldValue} checked={values[field.name]} />;
                } else if (field.type === "file") {
                  return (
                    <XFileInput
                      key={field.name}
                      id={field.name}
                      {...field}
                      touched={touched}
                      errors={errors}
                      onChange={(event: any) => {
                        setFieldValue(field.name, event.currentTarget.files[0]);
                      }}
                    />
                  );
                } else if (field.type === "date") {
                  return (
                    <XDatePickerField
                      key={field.name}
                      id={field.name}
                      {...field}
                      value={values[field.name]}
                      touched={touched}
                      onChange={setFieldValue}
                      errors={errors}
                    />
                  );
                } else {
                  return (
                    <XTextBox
                      key={field.name}
                      id={field.name}
                      {...field}
                      errors={errors}
                      touched={touched} />
                  );
                }
              })}

              {debug && <Col xs={12}>
                <pre className={"code"} style={{ width: "100%", height: "100%" }}>
                  {JSON.stringify({ values, errors, touched }, null, 2)}
                </pre>
              </Col>}

            </Row>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              Close
            </button>
            <Button disabled={isSubmitting} type="submit" className="btn btn-primary">
              {isSubmitting ? <Spinner size={"sm"} data-testid="loader"></Spinner> : props.submitButtonLabel || "Save"}
            </Button>
          </div>

        </Form>
      )}

    </Formik>
  );
};

export default CreateForm;