import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'IN PROGRESS', text: 'IN PROGRESS'},
  {value: 'COMPLETED', text: 'COMPLETED'},
  {value: 'PENDING', text: 'PENDING'},
  {value: 'PAUSED', text: 'PAUSED'},
  {value: 'CANCELED', text: 'CANCELED'}
]

export const ProgramFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 6}, position: 1 },
  { name: "status", type: "select", label: "Status", options: categories, size: {lg: 6}, position: 2 },
  { name: "startDate", type: "date", label: "Start Date", size: {lg: 6}, position: 3 },
  { name: "endDate", type: "date", label: "End Date", size: {lg: 6}, position: 4 },
  { name: "details", type: "textarea", label: "Details", size: {lg: 12}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);