import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo.png";
import { Urls } from "../../routes/Urls";

const Logout = () => {

    localStorage.removeItem("__strive__access__token__");
    localStorage.removeItem("__strive__user__");
    localStorage.removeItem("__strive__tenant__");
    document.dispatchEvent(new CustomEvent("logout", { detail: false }));

    window.location.href = "/login"

    return (
        <React.Fragment>
            <MetaTags>
                <title>Logged out</title>
            </MetaTags>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={4}>

                                <div className="text-center py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to="/sales" className="d-block auth-logo">
                                            <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                                            <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                                        </Link>
                                    </div>
                                    <div className="mb-4 mb-md-5">
                                        <div className="avatar-lg mx-auto">
                                            <div className="avatar-title bg-light text-secondary display-4 rounded-circle">
                                                <i className="fa fa-user-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-muted">
                                        <h4 className="">You are Logged Out</h4>
                                        <p>Thank you for using <span className="fw-semibold">Treda</span></p>
                                        <div className="mt-4">
                                            <Link to={Urls.auth.login} className="btn btn-info w-100">Sign In</Link>
                                        </div>
                                    </div>

                                    <div className="mt-5 text-center text-muted">
                                        <p>Don&apos;t have an account ? <Link to={Urls.auth.signup} className="fw-medium text-decoration-underline"> Signup </Link></p>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">&copy; {" "}{new Date().getFullYear()} Treda.</p>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Logout;