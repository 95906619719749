import React, { useState, useRef } from 'react';
import { MentionsInput, Mention } from 'react-mentions'

export const QueryBuilder = (props: any) => {

    return (
        <div className='mentions'>
        <MentionsInput value={props.value} onChange={(e, newValue, newPlainTextValue) => props.handleChange(e, newPlainTextValue)} placeholder="Write your desired query">
            <Mention
                trigger="@"
                data={props.data}
                displayTransform={(id, display) => `${id}`}
            />
        </MentionsInput>
        </div>
    );
}

export default QueryBuilder;