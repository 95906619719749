import { IField } from "../interfaces/inputs/IField";

const roles = [
  {value: 'Staff', text: 'Staff'},
  {value: 'Admin', text: 'Admin'},
  {value: 'Enumerator', text: 'Enumerator'}
]

export const UserAccessFields: IField[] = [
  { name: "role", type: "select", label: "Role", options: roles, size: {lg: 12}, position: 2 },
  { name: "emails", type: "textarea", label: "Emails (comma separated)", size: {lg: 12}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);