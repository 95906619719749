import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Button, Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { Loading } from "../../../components/common/Loading";
import { Form, Formik } from "formik";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";

const request = new ApiCore();

const AllData = () => {
  const { id } = useParams();
  const [tool, setTool] = useState<any>(null);
  const [fields, setFields] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    loadTool();
    loadData()
  }, []);

  const loadTool = () => {
    setLoading(true);
    request
      .get(`${Endpoints.tools}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setTool(data);

        let allFields: any[] = [];
        data.sections.forEach((element: any) => {
          element.fields.forEach((f: any) => {
            allFields.push(f)
          })
        });
        //setFields(allFields);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadData = () => {
    setLoadingData(true);
    request
      .get(`${Endpoints.tools}/${id}/data`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setAllData(data.data);
        setFields(data.fields);
        setLoadingData(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFieldList = () => {
    let list: ISelectOption[] = []
    tool.sections.forEach((s: any) => {
      s.fields.forEach((f: any) => {
        list.push({ value: f.id, text: f.label });
      })
    })
    return list;
  }

  const handleDelete = (values: any) => {
    console.log(values);
    if (values.batch && values.batch.length > 0) {
      if (window.confirm("Do you want to delete the selected data")) {
        setLoading(true);
        toast.promise(request.create(Endpoints.tools + "/" + tool.id + "/deletedata", values.batch ), {
          pending: "Please wait..",
          success: {
            render({ data }) {
              setInitialValues({})
              let message: any = "";
              return message;
            }
          }
        }).then((response: AxiosResponse) => {
          setInitialValues({})
          setLoading(false);
          loadData();
        });
      }
    }
  };

  return (
    <React.Fragment>
      {tool && (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        {tool.name}
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href={`/tools/${tool.id}`}>
                            Edit
                          </DropdownItem>
                          <DropdownItem href={`/tools/${tool.id}/data`}>
                            Fill Form
                          </DropdownItem>
                          <DropdownItem href={`/tools/${tool.id}/dashboard`}>
                            Dashboard
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <ToastContainer />
              {loadingData && <Loading label="Loading data. Please wait." />}
              {!loadingData &&
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validateOnBlur
                  onSubmit={(values: any) => handleDelete(values)}
                >
                  {({
                    isSubmitting,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  }) => (
                    <>
                      <Form>
                        <table className="table table-bordered">
                          <thead>
                          <tr>
                            <th></th>
                            {fields.map((field: any) => {
                              if (field.showInGrid) {
                                return (
                                  <th className="txt-left">
                                    {field.label}
                                  </th>
                                )
                              }
                            })}
                          </tr>
                          </thead>
                          <tbody>
                          {allData.map((data: any) => {
                            var recordId = data[data.length - 1];
                            console.log(data)
                            return <tr key={recordId}>
                              <td>
                                <XCheckbox
                                  id={`id${recordId}`}
                                  name={"batch"}
                                  label={""}
                                  onChange={setFieldValue}
                                  value={recordId} size={{}} />
                              </td>
                              {fields.map((field: any, index: number) => {
                                if (field.showInGrid) {
                                  return <td>
                                    {data[index]}
                                  </td>
                                }
                              })}
                            </tr>
                          })}
                          </tbody>
                        </table>
                        <div className="modal-footer">
                          <Button
                            type="submit"
                            className="btn btn-danger"
                          >
                            {loading ? <Spinner size={"sm"} data-testid="loader"></Spinner> : "Delete"}
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              }
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default AllData;
