import { Field } from "formik";
import { Col, Input, Label } from "reactstrap";
import React from "react";
import { ICheckboxProps } from "../../../interfaces/inputs/ICheckboxProps";

export const XCheckbox = (props: ICheckboxProps) => {
  return (
    <Col {...props.size}>
      <div className="form-check mb-2">
        {props.includeTopPadding ? <p>&nbsp;</p> : null}
        {props.multiValue ?
          <Field
            type="checkbox"
            name={props.name}
            value={props.value}
            checked={props.checked}
            id={props.id}
          /> : <>
            <input
              className="form-check-input"
              type="checkbox"
              checked={props.checked}
              value={props.value}
              onChange={(val) => {
                if (props.onChange) {
                  props.onChange(props.name, val.target.checked ? 1 : 0);
                }
              }}
              id={props.id}
            />
          </>
        }
        <Label className="form-check-label" htmlFor={props.id}>
          &nbsp;{props.label}
        </Label>
      </div>
    </Col>
  );
};
