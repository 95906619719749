import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../../components/common/XModal";
import CreateForm from "../../../components/forms/CreateForm";
import { Endpoints } from "../../../utils/Endpoints";
import { ToolCategoryFields } from "../../../fields/ToolCategoryFields";
import {toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { can } from "../../../utils/Helpers";
import { useHistory } from "react-router-dom";

const Categories = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [showCategoryDialog, setShowCategoryDialog] = useState<boolean>(false);
  const [showCategoryBulkDialog, setShowCategoryBulkDialog] = useState<boolean>(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<any>({
    firstname: "",
    lastname: ""
  });

  const tableRowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      history.push("/categories/" + row.id);
    }
 }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      formatter: (cellContent: any, item: any) => <span>{item.name}</span>
    },
    {
      dataField: "category",
      text: "Group"
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowCategoryDialog(true);
    } else if (type == "new") {
      setInitialValues({
        firstname: "",
        lastname: ""
      });
      setShowCategoryDialog(true);
    }
  }  

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: categories.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();

  useEffect(() => {
    searchCategories({})
  }, []);

  const handleUpdate = (item: any) => {
    window.location.reload();
  };

  const searchCategories = (values: any) => {
    toast.promise(request
    .get(Endpoints.categories, { params: values }, "json"), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
        console.log(response.data);
        setCategories(response.data);
        setLoading(false);
        
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>

        <>

          <XModal title={"Add a category"}
                  width={"lg"}
                  open={showCategoryDialog}
                  toggle={() => setShowCategoryDialog(!showCategoryDialog)}
                  onClose={() => setShowCategoryDialog(false)}>

            <CreateForm
              fields={ToolCategoryFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.categories + "/" + initialValues.id : Endpoints.categories}
              submitButtonLabel={initialValues.id ? "Edit Category" : "Add Category"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowCategoryDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Categories
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      {can("manage","School::Category") && (
                        <>
                          <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                          Add category
                          </DropdownItem>
                        </>
                      )}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading categories. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={categories}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                  />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </>

      </div>
    </React.Fragment>
  );
};

export default Categories;