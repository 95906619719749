import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { XRadioButton } from "../../../components/common/inputs/XRadioButton";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XTextArea } from "../../../components/common/inputs/XTextArea";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";

export const XRenderField = (props: any) => {
  const { field, touched, setFieldValue, values, handleChange, usePlaceHolders = false } = props;
  const options: [] = [];
  let renderedField: any = null;
  let selectOptions: ISelectOption[] = [];

  renderedField = <XTextBox id={`field.${field.id}`} name={`field[f${field.id}]`} type={"text"} touched={touched}  label={usePlaceHolders ? "" : field.label} placeholder={usePlaceHolders ? field.label : ""} onChange={setFieldValue} />

  if (field.category.toLowerCase() == "gender") {
    selectOptions = [
      { value: 'Female', text: 'Female' },
      { value: 'Male', text: 'Male' }
    ]
  } else if (field.category.toLowerCase() == "select") {
    selectOptions = field.optionList.split(",").map((option: any) => {
      return { value: option, text: option }
    })
  } else if (field.category.toLowerCase() == "country") {
    selectOptions = [
      { value: 'Kenya', text: 'Kenya' },
      { value: 'Rwanda', text: 'Rwanda' },
      { value: 'Tanzania', text: 'Tanzania' }
    ]
  }

  if (selectOptions.length > 0) {
    renderedField = <XSelect id={`field.${field.id}`} name={`field[f${field.id}]`} touched={touched} options={selectOptions} label={usePlaceHolders ? "" : field.label} placeholder={usePlaceHolders ? field.label : ""} />
  } else {
    if (field.category.toLowerCase() == "long text") {
      renderedField = <XTextArea id={`field.${field.id}`} name={`field[f${field.id}]`} touched={touched}  label={usePlaceHolders ? "" : field.label} placeholder={usePlaceHolders ? field.label : ""} size={{}} />
    } else if (field.category.toLowerCase() == "radio") {
      renderedField = []
      renderedField.push(<label>{field.label}:&nbsp;&nbsp;</label>)
      field.optionList.split(",").forEach((option: any, index: number) => {
        renderedField.push(<XRadioButton id={`field.${field.id}.${index}`} name={`field[f${field.id}]`} label={option} value={option} size={{}} onChange={setFieldValue} />) 
      })
    } else if (field.category.toLowerCase() == "checkbox") {
      renderedField = []
      renderedField.push(<label>{field.label}:&nbsp;&nbsp;</label>)
      field.optionList.split(",").forEach((option: any, index: number) => {
        renderedField.push(<XCheckbox id={`field.${field.id}.${index}`} name={`field[f${field.id}]`} label={option} value={option} size={{}} onChange={handleChange} multiValue={true} />) 
      })
    } /*else if (field.category.toLowerCase() == "date") {
      renderedField = <XDatePickerField
      key={`key${field.id}`}
      id={`field.${field.id}`}
      name={`field[f${field.id}]`}
      label={usePlaceHolders ? "" : field.label} placeholder={usePlaceHolders ? field.label : ""}
      touched={touched}
      onChange={setFieldValue}
      value={values[`field[f${field.id}]`]}
    />
    }*/
  }

  return (
    <>
    {renderedField}
    </>
  );
};