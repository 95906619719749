import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { allPrograms, allTenants, currentProgram, currentTenant, fullUrl, getStorableUser, setCurrentProgram, setCurrentTenant } from "../../../utils/Helpers";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Endpoints } from "../../../utils/Endpoints";
import { AxiosResponse } from "axios";
import { ApiCore } from "../../../helpers/api_helper";
import { Settings } from "../../../utils/Settings";

const ProgramsMenu = (props: any) => {
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const tenants = allPrograms();

  useEffect(() => {
    const tenant = currentProgram();
    if (tenant) {
      setUsername(tenant.name);
      setEmail(tenant.status);
      setRole(tenant.category);
      setAvatar(tenant.logo_path ? Settings.getFile(tenant.logo_path) : "");
    }
  }, [success]);

  const handleSwitch = (id: any) => {
    tenants.map((tenant: any) => {
      if (tenant.id == id) {
        setCurrentProgram(tenant);
        window.location.reload();
      }
    })
  };

  return (
    <React.Fragment>
    <ToastContainer />
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item user text-start d-flex align-items-center"
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          src={avatar}
          alt="logo"
          className="rounded-circle header-profile-user"
        />
        <span className="ms-2 d-none d-sm-block user-item-desc">
          <span className="user-name">{username}</span>
          <span className="user-sub-title">{role}</span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
        <div className="p-3 bg-primary border-bottom">
          <h6 className="mb-0 text-white">{username}</h6>
          <p className="mb-0 font-size-11 text-white-50 fw-semibold">
            {email}
          </p>
        </div>
        <DropdownItem divider />
        {tenants && tenants.map((tenant: any) => {
          return (
            <DropdownItem
              tag="a"
              to="#"
              onClick={() => {
                handleSwitch(tenant.id);
              }}
              key={tenant.id}
            >
              <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
              <span className="align-middle">{tenant.name}</span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProgramsMenu));
