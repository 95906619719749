import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { PartnerFields } from "../../fields/PartnerFields";
import BulkUpload from "./upload";
import { Settings } from "../../utils/Settings";
import { SaleUpdateFields } from "../../fields/SaleUpdateFields";
import { ProgramFields } from "../../fields/ProgramFields";
import { UserAccessFields } from "../../fields/UserAccessFields";
import { Loading } from "../../components/common/Loading";

const request = new ApiCore();

const PartnerDetails = () => {
  const { id } = useParams();
  const [partner, setPartner] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showProgramDialog, setShowProgramDialog] = useState<boolean>(false);
  const [showLogoDialog, setShowLogoDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  const [initialProgramValues, setInitialProgramValues] = useState<any>({});
  const [initialUserValues, setInitialUserValues] = useState<any>({});
  const [initialMessageValues, setInitialMessageValues] = useState<any>({});

  useEffect(() => {
    loadPartner();
  }, []);

  const loadPartner = () => {
    setLoading(true);
    request
      .get(`${Endpoints.partners}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setPartner(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleUpdate = (item: any) => {
    loadPartner();
  };

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadPartner();
    });
  }


  const handleUploadLogo = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      updatePartner(response.data);
    });
  }

  const updatePartner = (logo: any) => {
    partner.logo = logo;

    toast.promise(request.update(Endpoints.partners, partner), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      setShowLogoDialog(false);
      loadPartner();
    });
  }

  const handleClick = (record: any, action: string) => {
    if (action == "edit") {
      setInitialValues(record);
      setShowDialog(true);
    } else if (action == "edit_Program") {
      setInitialProgramValues(record);
      setShowProgramDialog(true);
    } else if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/uploads/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadPartner();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (action == "delete_user") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/api/partners/partnerusers/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadPartner();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  return (
    <React.Fragment>
      {loading && <Container fluid><p>&nbsp;</p><p>&nbsp;</p><br /><Loading label="Please wait.." /></Container>}
      {!loading && partner && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Partner"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={PartnerFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.partners}
                submitButtonLabel={initialValues.id ? "Edit Partner" : "Add Partner"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>
            <XModal title={"Program"}
              width={"lg"}
              open={showProgramDialog}
              toggle={() => setShowProgramDialog(!showDialog)}
              onClose={() => setShowProgramDialog(false)}>

              <CreateForm
                fields={ProgramFields}
                initialValues={initialProgramValues}
                submitEndpoint={`/api/partners/${partner.id}/program`}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowProgramDialog(false)} />

            </XModal>

            <XModal title={"Add users"}
              width={"lg"}
              open={showUserDialog}
              toggle={() => setShowUserDialog(!showUserDialog)}
              onClose={() => setShowUserDialog(false)}>

              <CreateForm
                fields={UserAccessFields}
                initialValues={initialUserValues}
                submitEndpoint={`/api/partners/${partner.id}/user`}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowUserDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: partner.id, entityType: "partner" }} />

            </XModal>

            <XModal title={"Logo"}
              width={"lg"}
              open={showLogoDialog}
              toggle={() => setShowLogoDialog(!showLogoDialog)}
              onClose={() => setShowLogoDialog(false)}>

              <BulkUpload handleSubmit={handleUploadLogo} initialValues={{ entityId: 0, entityType: "partner-logo" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Partner Details
                      </CardTitle>

                      <Button className="mt-4 mt-sm-0 btn-primary" onClick={() => { setInitialValues(partner); setShowDialog(true); }}>
                        <i className="fa fa-edit"></i> Edit
                      </Button>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <a href="#" className="partner-logo" onClick={() => setShowLogoDialog(true)}><img src={partner.logo ? Settings.getFile(partner.logo.path) : ""} alt="LOGO" /></a>
                        </Col>
                        <Col md={10}>
                          <Row>
                            <Col md={3}>
                              <div className="dt-label">Name</div>
                              <div className="dt-value">{partner.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Sector</div>
                              <div className="dt-value">{partner.sector}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Phone</div>
                              <div className="dt-value">{partner.telephone}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-label">Email</div>
                              <div className="dt-value">{partner.email}</div>
                            </Col>
                          </Row>
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        PROGRAMS
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowProgramDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {partner.programs.map((record: any) => {
                          return <Col md={4} lg={4} xl={4} key={record.id + '_upload'}>
                            <Card>
                              <CardBody>
                                <Row className="pg-card-top">
                                  <Col>
                                    <i className="fa fa-th-list"></i>
                                  </Col>
                                  <Col md={6} lg={6} xl={6}>
                                    <div className="pg-btn pg-btn-success">{record.status ? record.status : "PENDING"}</div>
                                  </Col>
                                </Row>
                                <br />
                                <h2><a href={`/programs/${record.id}`}>{record.name}</a></h2>
                                <p>
                                  <b>From:</b> {record.startDate ? formattedDate(record.startDate) : record.startDate}
                                  <br /><b>To:</b> {record.endDate ? formattedDate(record.endDate) : record.endDate}
                                </p>
                                <hr />
                                <Row className="pg-card-bottom">
                                  <Col>
                                    <b>Programs:</b> {record.programs ? record.programs.length : "None"}
                                  </Col>
                                  <Col md={6} lg={6} xl={6} className="txt-right">
                                    <b>Tools:</b> {record.tools ? record.tools.length : "None"}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        UPLOADS
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowUploadDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th></th></tr>
                        </thead>
                        <tbody>
                          {partner.uploads.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={Settings.getFile(record.path, "download")}>{record.name && record.name.length > 0 ? record.name : record.path}</a></td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_upload")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        USERS
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowUserDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th>Role</th><th></th></tr>
                        </thead>
                        <tbody>
                          {partner.partnerUsers && partner.partnerUsers.map((record: any) => {
                            return <tr key={record.id + '_user'}>
                              <td>{record.user.name}</td>
                              <td>{record.role}</td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_user")}><i className="fa fa-trash red"></i></a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default PartnerDetails;
