import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import BulkUpload from "./upload";
import { Settings } from "../../utils/Settings";
import { ToolFields } from "../../fields/ToolFields";
import { OutcomeFields } from "../../fields/OutcomeFields";
import { IndicatorFields } from "../../fields/IndicatorFields";
import { AssumptionFields } from "../../fields/AssumptionFields";
import { GoalFields } from "../../fields/GoalFields";

const request = new ApiCore();

const OutcomeDetails = () => {
  const { id } = useParams();
  const [outcome, setOutcome] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);
  const [showGoalDialog, setShowGoalDialog] = useState<boolean>(false);
  const [showOutputDialog, setShowOutputDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showIndicatorDialog, setShowIndicatorDialog] = useState<boolean>(false);
  const [showAssumptionDialog, setShowAssumptionDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [initialOutputValues, setInitialOutputValues] = useState<any>({});
  const [initialLogframeValues, setInitialLogframeValues] = useState<any>({});
  const [initialProjectValues, setInitialProjectValues] = useState<any>({});

  useEffect(() => {
    loadOutcome();
  }, []);

  const loadOutcome = () => {
    request
      .get(`${Endpoints.logframe}/outcomes/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setOutcome(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadOutcome();
  };

  const handleClick = (record: any, action: string) => {
    if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/uploads/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadOutcome();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }
    values.parentId = outcome.id;
    values.parentType = "outcome";

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }

  const handleBeforeLogframe = (values: any) => {
    values.parentId = outcome.id;
    values.parentType = "outcome";
    return values;
  }

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadOutcome();
    });
  }

  return (
    <React.Fragment>
      {outcome && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Outcome"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={OutcomeFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.logframe + "/outcomes"}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>

            <XModal title={"Add a tool"}
              width={"sm"}
              open={showToolDialog}
              toggle={() => setShowToolDialog(!showToolDialog)}
              onClose={() => setShowToolDialog(false)}>

              <CreateForm
                fields={ToolFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.tools}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeSubmit}
                onClose={() => setShowToolDialog(false)} />

            </XModal>

            <XModal title={"Add a goal"}
              width={"sm"}
              open={showGoalDialog}
              toggle={() => setShowGoalDialog(!showGoalDialog)}
              onClose={() => setShowGoalDialog(false)}>

              <CreateForm
                fields={GoalFields}
                initialValues={initialLogframeValues}
                submitEndpoint={Endpoints.logframe + "/goals"}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeLogframe}
                onClose={() => setShowGoalDialog(false)} />

            </XModal>

            <XModal title={"Add Indicator"}
              width={"sm"}
              open={showIndicatorDialog}
              toggle={() => setShowIndicatorDialog(!showIndicatorDialog)}
              onClose={() => setShowIndicatorDialog(false)}>

              <CreateForm
                fields={IndicatorFields}
                initialValues={initialLogframeValues}
                submitEndpoint={Endpoints.logframe + "/indicators"}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeLogframe}
                onClose={() => setShowIndicatorDialog(false)} />

            </XModal>

            <XModal title={"Add Output"}
              width={"sm"}
              open={showOutputDialog}
              toggle={() => setShowOutputDialog(!showOutputDialog)}
              onClose={() => setShowOutputDialog(false)}>

              <CreateForm
                fields={OutcomeFields}
                initialValues={initialOutputValues}
                submitEndpoint={Endpoints.logframe + "/outputs/" + outcome.id}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeLogframe}
                onClose={() => setShowOutputDialog(false)} />

            </XModal>

            <XModal title={"Add Assumption"}
              width={"sm"}
              open={showAssumptionDialog}
              toggle={() => setShowAssumptionDialog(!showAssumptionDialog)}
              onClose={() => setShowAssumptionDialog(false)}>

              <CreateForm
                fields={AssumptionFields}
                initialValues={initialLogframeValues}
                submitEndpoint={Endpoints.logframe + "/assumptions"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowAssumptionDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: outcome.id, entityType: "outcome" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Outcome Details
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(outcome); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => {
                            setInitialLogframeValues({ parentId: outcome.id, parentType: "outcome" });
                            setShowGoalDialog(true);
                          }}>
                            Add Sub Log Frame
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowToolDialog(true)}>
                            Add Tool
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowOutputDialog(true)}>
                            Add Output
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => {
                            setInitialLogframeValues({ parentId: outcome.id, parentType: "outcome" });
                            setShowIndicatorDialog(true)
                          }}>
                            Add Indicator
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => {
                            setInitialLogframeValues({ parentId: outcome.id, parentType: "outcome" });
                            setShowAssumptionDialog(true)
                          }}>
                            Add Assumption
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowUploadDialog(true)}>
                            Upload documents
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={12}>
                              <div className="dt-value">{outcome.code} - {outcome.name}</div>
                            </Col>
                          </Row>
                          {outcome.details && outcome.details.length > 0 && <><hr />
                            <Row>
                              <Col md={12}>
                                <div className="dt-value">{outcome.details}</div>
                              </Col>
                            </Row></>}
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <Card>
                    <CardBody>
                      <b>OUTPUTS</b><br />
                      <table className="table bordered">
                        <tbody>
                          {outcome.outputs.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right">{record.code} - <a href={`/logframe/outputs/${record.id}`}>{record.name}</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  {outcome.indicators && outcome.indicators.length > 0 &&
                    <Card>
                      <CardBody>
                        <b>INDICATORS</b><br />
                        <table className="table bordered">
                          <tbody>
                            {outcome.indicators.map((record: any) => {
                              return <tr key={record.id + '_upload'}>
                                <td className="txt-right">{record.code} - <a href={`/logframe/indicators/${record.id}`}>{record.name}</a></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>}

                    {outcome.assumptions && outcome.assumptions.length > 0 &&
                    <Card>
                      <CardBody>
                        <b>ASSUMPTIONS</b><br />
                        <table className="table bordered">
                          <tbody>
                            {outcome.assumptions.map((record: any) => {
                              return <tr key={record.id + '_upload'}>
                                <td className="txt-right">{record.code} - <a href={`/logframe/assumptions/${record.id}`}>{record.name}</a></td>
                              </tr>
                            })}
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>}
                </Col>
                <Col>
                {outcome.uploads && outcome.uploads.length > 0 &&
                  <Card>
                    <CardBody>
                      <b>UPLOADS</b><br />
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th></th></tr>
                        </thead>
                        <tbody>
                          {outcome.uploads.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={Settings.getFile(record.path, "download")}>{record.name && record.name.length > 0 ? record.name : record.path}</a></td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_upload")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  }
                  {outcome.tools && outcome.tools.length > 0 && <Card>
                    <CardBody>
                      <b>DATA TOOLS</b><br />
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th></tr>
                        </thead>
                        <tbody>
                          {outcome.tools.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/tools/${record.id}`}>{record.name}</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>}
                  {outcome.goals && outcome.goals.length > 0 && <Card>
                    <CardBody>
                      <b>SUB LOG FRAME</b><br />
                      <table className="table bordered">
                        <tbody>
                          {outcome.goals.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right">{record.code} - <a href={`/logframe/goals/${record.id}`}>{record.name}</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>}
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default OutcomeDetails;
