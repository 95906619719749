import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { Urls } from "../../../routes/Urls";
import { can, currentTenant } from "../../../utils/Helpers";

const ProfileMenu = (props: any) => {
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");

  useEffect(() => {
    const current_user = localStorage.getItem("__strive__user__");
    if (current_user) {
      const user = JSON.parse(current_user);
      setUsername(user.name)
      setEmail(user.email);
      setRole(user.admin ? "Administrator" : "")
      setAvatar(user.avatar)
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/*<img*/}
          {/*  className="rounded-circle header-profile-user"*/}
          {/*  src={avatar}*/}
          {/*  alt="Header Avatar"*/}
          {/*/>*/}
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{username}</span>
            <span className="user-sub-title">{role}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{username}</h6>
            <p className="mb-0 font-size-11 text-white-50 fw-semibold">{email}</p>
          </div>
          <DropdownItem divider />
          <DropdownItem tag="a" href={Urls.auth.logout}>
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Logout")}</span>
            </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileMenu));
