import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Button, Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";

//Import React Router
import { useParams } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Rectangle, Cell, PieChart, Pie } from 'recharts';
import { Loading } from "../../components/common/Loading";
import { Endpoints } from "../../utils/Endpoints";
import { XIndicatorCard } from "../../components/common/XIndicatorCard";


const Dashboard = () => {
    const { id } = useParams();
    const [tool, setTool] = useState<any>(null);
    const [fields, setFields] = useState<any>([]);
    const [indicators, setIndicators] = useState<any>([]);
    const [queries, setQueries] = useState<any>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [loadingIndicators, setLoadingIndicators] = useState<boolean>(false);
    const [loadingQueries, setLoadingQueries] = useState<boolean>(false);

    const graphData = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];

    const pieData = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ];

    const COLORS: any[] = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: { cx: number, cy: number, midAngle: any, innerRadius: any, outerRadius: any, percent: any, index: any }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const getGraphData = (record: any) => {
        var data: any[] = [];
        var labels = record.labels.split(",");
        record.data.forEach((r: any[]) => {
            var el: { [key: string]: any } = { name: r[labels.length - 1] };
            for (let i = 0; i < labels.length - 1; i++) {
                el[labels[i]] = Math.round(r[i]);
            }
            data.push(el);
        })
        let dt = [
            {
                name: 'Page A',
                F1: 4000,
                F2: 2400,
            },
            {
                name: 'Page B',
                F1: 3000,
                F2: 1398,
            },
            {
                name: 'Page C',
                F1: 2000,
                F2: 9800,
            },
        ];
        console.log(data);
        return data;
    }

    const request = new ApiCore();

    const getIndicators = () => {
        setLoadingIndicators(true);
        request
            .get(`${Endpoints.dashboard}/indicators`, {})
            .then((response: AxiosResponse) => {
                const { data } = response;
                setIndicators(data);
                setLoadingIndicators(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingIndicators(false);
            });
    };

    const getQueries = () => {
        setLoadingQueries(true);
        request
            .get(`${Endpoints.dashboard}/queries`, {})
            .then((response: AxiosResponse) => {
                const { data } = response;
                setQueries(data);
                setLoadingQueries(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingQueries(false);
            });
    };

    useEffect(() => {
        getIndicators();
        getQueries();
    }, []);

    return (
        <React.Fragment>
            <>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={12} xl={12}>
                                {loadingIndicators && <Loading label="Loading data. Please wait." />}
                                {!loadingIndicators && <>
                                    {indicators.map((record: any) => {
                                        return <Card key={record.id}>
                                            <CardHeader className="justify-content-between d-flex align-items-center">
                                                <CardTitle>
                                                {record.name}
                                                </CardTitle>
                                                <a className="mt-4 mt-sm-0 btn btn-primary" href={`/logframe/indicators/${record.id}`} >
                                                    <i className="fa fa-folder-open"></i>
                                                </a>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    {record.windows.map((win: any) => {
                                                        return <Col><XIndicatorCard record={win} /></Col>
                                                    })}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    })}
                                </>}

                                {!loadingQueries && <Row>
                                    {queries.map((record: any) => {
                                        return <Col md={6}><Card key={record.id}>
                                            <CardHeader>{record.title}</CardHeader>
                                            <CardBody>
                                                <div className="area-chart-wrapper" style={{ width: '100%', height: '400px' }}>
                                                    <ResponsiveContainer>
                                                        <BarChart
                                                            data={getGraphData(record)}
                                                            margin={{
                                                                top: 5,
                                                                right: 30,
                                                                left: 20,
                                                                bottom: 5,
                                                            }}
                                                        >

                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            <Legend />
                                                            {record.labels && record.labels.split(",").slice(0, -1).map((label: any, idx: number) => {
                                                                if (record.labels.split(",").slice(0, -1).length > 1) {
                                                                    return <Bar dataKey={label} fill={COLORS[idx]} stackId="a" activeBar={<Rectangle stroke="blue" />} />
                                                                } else {
                                                                    return <Bar dataKey={label} fill={COLORS[idx]} activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                                                }

                                                            })}
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </CardBody>
                                        </Card></Col>
                                    })}
                                </Row>}
                            </Col>
                        </Row>
                    </Container>
                    <br />
                </div>
            </>
        </React.Fragment>
    );
};

export default Dashboard;
