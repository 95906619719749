import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'client', text: 'Client'},
  {value: 'implementor', text: 'Implementor'}
]

const sectors = [
  {value: 'development', text: 'Development'},
  {value: 'commercial', text: 'Commercial'}
]

export const PartnerFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 4}, position: 1 },
  { name: "telephone", type: "text", label: "Phone", size: {lg: 4}, position: 2 },
  { name: "email", type: "text", label: "Email", size: {lg: 4}, position: 3 },
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 4}, position: 14 },
  { name: "sector", type: "select", label: "Sector", options: sectors, size: {lg: 4}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);