import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XSelect } from "../../components/common/inputs/XSelect";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { useLocation, useHistory } from "react-router-dom";
import { getForms } from "../../utils/Helpers";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";

const Search = (props: any) => {
  const [defaults, setDefaults] = useState<any>({});
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const params = new URLSearchParams(useLocation().search);

  const categories: ISelectOption[] = [
    { value: "client", text: "Client" },
    { value: "implementor", text: "Implementor" },
  ];

  let initialValues: any = {
    telephone: "",
    category: "",
    name: ""
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
            <>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={3}>
                          <XSelect
                            key="category"
                            id="category"
                            name={"category"}
                            options={categories}
                            placeholder={"Category"}
                            touched={touched}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XTextBox
                            type="text"
                            key="name"
                            id="name"
                            name={"name"}
                            placeholder={"Name"}
                            touched={touched}
                            value={values.name}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XTextBox
                            type="text"
                            key="telephone"
                            id="telephone"
                            name={"telephone"}
                            placeholder={"Phone"}
                            touched={touched}
                            value={values.telephone}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
      </div>
    </React.Fragment>
  );
};

export default Search;
