import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProgramFields } from "../../fields/ProgramFields";
import BulkUpload from "./upload";
import { ProjectFields } from "../../fields/ProjectFields";
import { Settings } from "../../utils/Settings";
import { ToolFields } from "../../fields/ToolFields";
import { GoalFields } from "../../fields/GoalFields";
import { ProgramWindowFields } from "../../fields/ProgramWindowFields";
import { UserAccessFields } from "../../fields/UserAccessFields";
import { Loading } from "../../components/common/Loading";

const request = new ApiCore();

const ProgramDetails = () => {
  const { id } = useParams();
  const [program, setProgram] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);
  const [showGoalDialog, setShowGoalDialog] = useState<boolean>(false);
  const [showWindowDialog, setShowWindowDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [initialLogframeValues, setInitialLogframeValues] = useState<any>({});

  const [initialWindowValues, setInitialWindowValues] = useState<any>({});
  const [initialUserValues, setInitialUserValues] = useState<any>({});
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);

  useEffect(() => {
    loadProgram();
  }, []);

  const loadProgram = () => {
    setLoading(true);
    request
      .get(`${Endpoints.partners}/programs/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setProgram(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleUpdate = (item: any) => {
    loadProgram();
  };

  const handleClick = (record: any, action: string) => {
    if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/uploads/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadProgram();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (action == "delete_user") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/api/partners/programusers/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadProgram();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }

  const handleBeforeLogframe = (values: any) => {
    values.program = {
      id: parseInt(program.id)
    }

    delete values.programId;
    delete values.created;
    return values;
  }

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadProgram();
    });
  }

  return (
    <React.Fragment>
      {loading && <Container fluid><p>&nbsp;</p><p>&nbsp;</p><br /><Loading label="Please wait.." /></Container>}
      {program && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Program"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={ProgramFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.partners + "/programs"}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>

            <XModal title={"Add users"}
              width={"lg"}
              open={showUserDialog}
              toggle={() => setShowUserDialog(!showUserDialog)}
              onClose={() => setShowUserDialog(false)}>

              <CreateForm
                fields={UserAccessFields}
                initialValues={initialUserValues}
                submitEndpoint={`/api/partners/programs/${program.id}/user`}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowUserDialog(false)} />

            </XModal>

            <XModal title={"Add a tool"}
              width={"sm"}
              open={showToolDialog}
              toggle={() => setShowToolDialog(!showToolDialog)}
              onClose={() => setShowToolDialog(false)}>

              <CreateForm
                fields={ToolFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.partners + "/programs/" + program.id + "/tool"}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeSubmit}
                onClose={() => setShowToolDialog(false)} />

            </XModal>

            <XModal title={"Add a Goal"}
              width={"sm"}
              open={showGoalDialog}
              toggle={() => setShowGoalDialog(!showGoalDialog)}
              onClose={() => setShowGoalDialog(false)}>

              <CreateForm
                fields={GoalFields}
                initialValues={initialLogframeValues}
                submitEndpoint={Endpoints.logframe + "/goals/" + program.id}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeLogframe}
                onClose={() => setShowGoalDialog(false)} />

            </XModal>

            <XModal title={"Add Window"}
              width={"sm"}
              open={showWindowDialog}
              toggle={() => setShowWindowDialog(!showWindowDialog)}
              onClose={() => setShowWindowDialog(false)}>

              <CreateForm
                fields={ProgramWindowFields}
                initialValues={initialWindowValues}
                submitEndpoint={initialWindowValues.id ? Endpoints.partners + "/programwindows" : Endpoints.partners + "/programs/" + program.id + "/window"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowWindowDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: program.id, entityType: "program" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Program Details
                      </CardTitle>


                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => { setInitialValues(program); setShowDialog(true); }}>
                          <i className="fa fa-edit"></i> Edit
                        </Button>&nbsp;
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(program); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => window.location.href = `/plan/${program.id}`}>
                            M&E Plan
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              <div className="dt-value">{program.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-value"><i className="fa fa-calendar green"></i> {program.startDate ? formattedDate(program.startDate) : program.startDate}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-value"><i className="fa fa-calendar red"></i> {program.endDate ? formattedDate(program.endDate) : program.endDate}</div>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col md={12}>
                              <div className="dt-value">{program.details}</div>
                            </Col>
                          </Row>
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Programs
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => {
                        setInitialValues({ parentId: program.id });
                        setShowDialog(true);
                      }}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th>Start Date</th><th>End Date</th></tr>
                        </thead>
                        <tbody>
                          {program.programs.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/programs/${record.id}`}>{record.name}</a></td>
                              <td>
                                {record.startDate ? formattedDate(record.startDate) : record.startDate}
                              </td>
                              <td>
                                {record.endDate ? formattedDate(record.endDate) : record.endDate}
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Review Windows
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowWindowDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Label</th><th>Start Date</th><th>End Date</th><th></th></tr>
                        </thead>
                        <tbody>
                          {program.windows && program.windows.map((record: any) => {
                            return <tr key={record.id + '_window'}>
                              <td className="txt-right">{record.label}</td>
                              <td>
                                {record.startDate ? formattedDate(record.startDate) : record.startDate}
                              </td>
                              <td>
                                {record.endDate ? formattedDate(record.endDate) : record.endDate}
                              </td>
                              <td><a href="#" onClick={() => { setInitialWindowValues(record); setShowWindowDialog(true) }}><i className="fa fa-edit"></i> Edit</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Goals
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowGoalDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <tbody>
                          {program.goals && program.goals.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right">{record.code} - <a href={`/logframe/goals/${record.id}`}>{record.name}</a></td>

                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Documents
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowUploadDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th></th></tr>
                        </thead>
                        <tbody>
                          {program.uploads.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={Settings.getFile(record.path, "download")}>{record.name && record.name.length > 0 ? record.name : record.path}</a></td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_upload")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Tools
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowToolDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th></tr>
                        </thead>
                        <tbody>
                          {program.tools.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/tools/${record.id}`}>{record.name}</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Users
                      </CardTitle>
                      <Button className="mt-4 mt-sm-0 btn btn-primary" onClick={() => setShowUserDialog(true)}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th>Role</th><th></th></tr>
                        </thead>
                        <tbody>
                          {program.users && program.users.map((record: any) => {
                            return <tr key={record.id + '_user'}>
                              <td>{record.user.name}</td>
                              <td>{record.role}</td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_user")}><i className="fa fa-trash red"></i></a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProgramDetails;
