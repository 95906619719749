import React, { useEffect, useState } from "react";

//import Breadcrumbs
import { Loading } from "../../components/common/Loading";

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XCheckbox } from "../../components/common/inputs/XCheckbox";
import { XFileInput } from "../../components/common/inputs/XFileInput";
import { XSelect } from "../../components/common/inputs/XSelect";
import { XTextBox } from "../../components/common/inputs/XTextBox";

const TargetForm = (props: any) => {

  const handleSubmit = (values: any) => {
    props.handleSubmit(values);
  };

  return (
    <Formik
    initialValues={props.initialValues}
    enableReinitialize
    validateOnBlur
    onSubmit={(values: any) => handleSubmit(values)}
  >
    {({ isSubmitting, setFieldValue, values, errors, touched }) => (
      <>
        <Form>
          <div className="modal-body">
            <Row>
              <Col lg={6}>
                <XSelect
                  key="programWindow"
                  id="programWindow"
                  name={"programWindow"}
                  options={props.windows}
                  placeholder={"Review Window"}
                  touched={touched}
                  errors={errors}
                />
              </Col>
              <Col lg={3}>
                <XTextBox
                  type="number"
                  key="target"
                  id="target"
                  name={"target"}
                  placeholder={"Target"}
                  touched={touched}
                  value={values.target}
                  errors={errors}
                />
              </Col>
              <Col lg={1}>
                <Button
                  disabled={false}
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <hr />
      </>
    )}
  </Formik>
  );
};

export default TargetForm;
