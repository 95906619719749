import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import BulkUpload from "./upload";
import { Settings } from "../../utils/Settings";
import { ToolFields } from "../../fields/ToolFields";
import { ActivityFields } from "../../fields/ActivityFields";
import { IndicatorFields } from "../../fields/IndicatorFields";

const request = new ApiCore();

const ActivityDetails = () => {
  const { id } = useParams();
  const [activity, setActivity] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);
  const [showGoalDialog, setShowGoalDialog] = useState<boolean>(false);
  const [showActivityDialog, setShowActivityDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showIndicatorDialog, setShowIndicatorDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [initialActivityValues, setInitialActivityValues] = useState<any>({});
  const [initialLogframeValues, setInitialLogframeValues] = useState<any>({});
  const [initialProjectValues, setInitialProjectValues] = useState<any>({});

  useEffect(() => {
    loadActivity();
  }, []);

  const loadActivity = () => {
    request
      .get(`${Endpoints.logframe}/activities/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setActivity(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadActivity();
  };

  const handleClick = (record: any, action: string) => {
    if (action == "delete_upload") {
      if (window.confirm("Do you want to delete this record?")) {
        request
          .delete(`/uploads/${record.id}`, {})
          .then((response: AxiosResponse) => {
            loadActivity();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }
    values.parentId = activity.id;
    values.parentType = "activity";

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }

  const handleBeforeLogframe = (values: any) => {
    values.parentId = activity.id;
    values.parentType = "activity";
    return values;
  }

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadActivity();
    });
  }

  return (
    <React.Fragment>
      {activity && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Activity"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={ActivityFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.logframe + "/activities"}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>

            <XModal title={"Add a tool"}
              width={"sm"}
              open={showToolDialog}
              toggle={() => setShowToolDialog(!showToolDialog)}
              onClose={() => setShowToolDialog(false)}>

              <CreateForm
                fields={ToolFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.tools}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeSubmit}
                onClose={() => setShowToolDialog(false)} />

            </XModal>

            <XModal title={"Add a goal"}
              width={"sm"}
              open={showGoalDialog}
              toggle={() => setShowGoalDialog(!showGoalDialog)}
              onClose={() => setShowGoalDialog(false)}>

              <CreateForm
                fields={ActivityFields}
                initialValues={initialLogframeValues}
                submitEndpoint={Endpoints.logframe + "/goals"}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeLogframe}
                onClose={() => setShowGoalDialog(false)} />

            </XModal>

            <XModal title={"Add Activity"}
              width={"sm"}
              open={showActivityDialog}
              toggle={() => setShowActivityDialog(!showActivityDialog)}
              onClose={() => setShowActivityDialog(false)}>

              <CreateForm
                fields={ActivityFields}
                initialValues={initialActivityValues}
                submitEndpoint={Endpoints.logframe + "/activities/" + activity.id}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeLogframe}
                onClose={() => setShowActivityDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: activity.id, entityType: "activity" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Activity Details
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(activity); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => {
                            setInitialLogframeValues({ parentId: activity.id, parentType: "activity" });
                            setShowGoalDialog(true);
                          }}>
                            Add Goal
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowToolDialog(true)}>
                            Add Tool
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowUploadDialog(true)}>
                            Upload documents
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={12}>
                              <div className="dt-value">{activity.code} - {activity.name}</div>
                            </Col>
                          </Row>
                          {activity.details && activity.details.length > 0 && <><hr />
                            <Row>
                              <Col md={12}>
                                <div className="dt-value">{activity.details}</div>
                              </Col>
                            </Row></>}
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={8} lg={8} xl={8}>
                  {activity.tools && activity.tools.length > 0 && <Card>
                    <CardBody>
                      <b>DATA TOOLS</b><br />
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th></tr>
                        </thead>
                        <tbody>
                          {activity.tools.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/tools/${record.id}`}>{record.name}</a></td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>}
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <b>UPLOADS</b><br />
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th></th></tr>
                        </thead>
                        <tbody>
                          {activity.uploads.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={Settings.getFile(record.path, "download")}>{record.name && record.name.length > 0 ? record.name : record.path}</a></td>
                              <td>
                                <a href="#" onClick={() => handleClick(record, "delete_upload")}>Delete</a>
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>

                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ActivityDetails;
