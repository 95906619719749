import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'admin', text: 'Admin'},
  {value: 'user', text: 'Normal User'}
]

export const UserFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 4}, position: 1 },
  { name: "phone", type: "text", label: "Phone", size: {lg: 4}, position: 2 },
  { name: "email", type: "email", label: "Email", size: {lg: 4}, position: 3 },
  { name: "password", type: "password", label: "Password", size: {lg: 4}, position: 4 },
  { name: "role", type: "select", label: "Role", options: categories, size: {lg: 4}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);