import React, { useState } from "react";

//import Breadcrumbs

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { ISelectOption } from "../../interfaces/inputs/ISelectOption";
import { useLocation } from "react-router-dom";
import { XTextBox } from "../../components/common/inputs/XTextBox";

const Search = (props: any) => {
  const [defaults, setDefaults] = useState<any>({});
  const [studentcategories, setStudentcategories] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);


  let initialValues: any = {
    phone: "",
    email: "",
    name: ""
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
            <>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmit(values)}
              >
                {({ values, errors, touched }) => (
                  <>
                    <Form>
                      <Row>
                        <Col lg={3}>
                          <XTextBox
                            type="text"
                            key="name"
                            id="name"
                            name={"name"}
                            placeholder={"Name"}
                            touched={touched}
                            value={values.name}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XTextBox
                            type="text"
                            key="telephone"
                            id="telephone"
                            name={"phone"}
                            placeholder={"Phone"}
                            touched={touched}
                            value={values.phone}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={3}>
                          <XTextBox
                            type="text"
                            key="email"
                            id="email"
                            name={"email"}
                            placeholder={"Email"}
                            touched={touched}
                            value={values.email}
                            errors={errors}
                          />
                        </Col>
                        <Col lg={1}>
                          <Button
                            disabled={false}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    <hr/>
                  </>
                )}
              </Formik>
            </>
      </div>
    </React.Fragment>
  );
};

export default Search;
