import { Card } from "@material-ui/core";
import { Field } from "formik";
import { CardBody, Col, Label, Row } from "reactstrap";

export const XIndicatorCard = (props: any) => {

  const { record } = props;

  const getProgress = () => {
    var p = 0;
    if (record.actual && record.actual > 0 && record.target && record.target > 0) {
      p = (record.actual / record.target) * 100
    }
    return p;
  }

  const getClassName = () => {
    var p = Number(getProgress());
    var c = "";
    if (p > 0 && p <= 50) {
      c = "bg-danger";
    } else if (p > 50 && p < 100) {
      c = "bg-warning"
    } else if (p > 50 && p < 100) {
      c = "bg-success"
    }
    return c;
  } 

  return (
    <Card className="indicator">
      <CardBody>
        <div><b>{record.label}</b></div>
        <Row>
          <Col className="target">
            <div>Target</div>
            <div className="val">{record.target}</div>
          </Col>
          <Col className="actual">
            <div>Actual</div>
            <div className="val">{record.actual}</div>
          </Col>
        </Row>
        <br/>
        <div className="progress" style={{ height: '15px' }}>
          <div className={`progress-bar progress-bar-striped ${getClassName()}`} role="progressbar" style={{ width: getProgress() + "%" }} aria-valuenow={getProgress()} aria-valuemin={0} aria-valuemax={100}></div>
        </div>

      </CardBody>
    </Card>
  );
};