import React, { } from "react";

//import Breadcrumbs

import { Button, Col, Container, Row } from "reactstrap";

import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { Endpoints } from "../../../utils/Endpoints";

const Search = (props: any) => {

  let initialValues: any = {
    categoryId: 0,
    name: ""
  };

  const handleSubmit = (values: any) => {
    props.handleSearch(values);
  };

  return (
    <React.Fragment>
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validateOnBlur
          onSubmit={(values: any) => handleSubmit(values)}
        >
          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
            <>
              <Form>
                <Row>
                  <Col lg={3}>
                    <XSelect
                      key="category"
                      id="category"
                      name={"categoryId"}
                      dataSrc={Endpoints.categories}
                      placeholder="Category"
                      touched={touched}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6}>
                    <XTextBox
                      type="text"
                      key="name"
                      id="name"
                      name={"name"}
                      placeholder="Name"
                      touched={touched}
                      value={values.name}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={1}>
                    <Button
                      disabled={false}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />
            </>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default Search;
