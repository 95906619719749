import { Field } from "formik";
import { Col, Input, Label } from "reactstrap";
import React from "react";
import { ICheckboxProps } from "../../../interfaces/inputs/ICheckboxProps";

export const XRadioButton = (props: ICheckboxProps) => {
  return (
    <Col {...props.size}>
      <div className="form-check mb-2">
        {props.includeTopPadding ? <p>&nbsp;</p> : null}
        <input
          className="form-check-input"
          type="radio"
          checked={props.checked}
          name={props.name}
          value={props.value}
          onChange={(val) => {
            if (props.onChange) {
              props.onChange(props.name,val.target.checked ? props.value : "");
            }
          }}
          id={props.id}
        />
        <Label className="form-check-label" htmlFor={props.id}>
          {props.label}
        </Label>
      </div>
    </Col>
  );
};
