import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProgramFields } from "../../fields/ProgramFields";
import BulkUpload from "./upload";
import { ProjectFields } from "../../fields/ProjectFields";
import { Settings } from "../../utils/Settings";
import { ToolFields } from "../../fields/ToolFields";
import { GoalFields } from "../../fields/GoalFields";
import { ProgramWindowFields } from "../../fields/ProgramWindowFields";

const request = new ApiCore();

const ProgramDetails = () => {
  const { id } = useParams();
  const [plan, setPlan] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);
  const [showGoalDialog, setShowGoalDialog] = useState<boolean>(false);
  const [showWindowDialog, setShowWindowDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [initialLogframeValues, setInitialLogframeValues] = useState<any>({});

  const [initialWindowValues, setInitialWindowValues] = useState<any>({});

  useEffect(() => {
    loadPlan();
  }, []);

  const loadPlan = () => {
    request
      .get(`${Endpoints.partners}/programs/${id}/plan`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setPlan(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }


  return (
    <React.Fragment>
      {plan && (
        <>
          <div className="page-content">
            <ToastContainer />

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        M&E Plan
                      </CardTitle>

                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <table className="table bordered">
                          <thead>
                            <tr>
                              <th rowSpan={2}>Item</th><th rowSpan={2}>Code</th><th rowSpan={2}>Summary</th><th rowSpan={2}>Indicator</th><th rowSpan={2}>MOV</th>
                              {plan.windows && plan.windows.map((w: any) => {
                                return <th colSpan={3} className="txt-center">{w.label}</th>
                              })}
                            </tr>
                            <tr>
                              {plan.windows && plan.windows.map((w: any) => {
                                return <><th>TGT</th><th>ACT</th><th>%</th></>
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {plan.goals && plan.goals.map((record: any) => {
                              return <><tr key={record.id + '_upload'}>
                                <td className="txt-right">Goal</td>
                                <td>{record.code}</td>
                                <td className="txt-right"><a href={`/programs/${record.id}`}>{record.name}</a></td>
                                <td>
                                  {record.indicators && record.indicators.length > 0 ? <>
                                    {record.indicators[0].name}
                                  </> : <></>}
                                </td>
                                <td></td>
                                {plan.windows && plan.windows.map((w: any) => {
                                  return <><th>-/-</th><th>-/-</th><th>-/-</th></>
                                })}
                              </tr>
                                {record.indicators && record.indicators.length > 1 ? <></> : <></>}
                                {record.outcomes && record.outcomes.map((record: any) => {
                                  return <>
                                    <tr key={record.id + '_upload'}>
                                      <td className="txt-right">Outcome</td>
                                      <td>{record.code}</td>
                                      <td className="txt-right"><a href={`/programs/${record.id}`}>{record.name}</a></td>
                                      <td>
                                        {record.indicators && record.indicators.length > 0 ? <>
                                          {record.indicators[0].name}
                                        </> : <></>}
                                      </td>
                                      <td></td>
                                      {plan.windows && plan.windows.map((w: any) => {
                                        return <><th>-/-</th><th>-/-</th><th>-/-</th></>
                                      })}
                                    </tr>
                                    {record.outputs && record.outputs.map((record: any) => {
                                      return <>
                                        <tr key={record.id + '_upload'}>
                                          <td className="txt-right">Output</td>
                                          <td>{record.code}</td>
                                          <td className="txt-right"><a href={`/programs/${record.id}`}>{record.name}</a></td>
                                          <td>
                                            {record.indicators && record.indicators.length > 0 ? <>
                                              {record.indicators[0].name}
                                            </> : <></>}
                                          </td>
                                          <td></td>
                                          {plan.windows && plan.windows.map((w: any) => {
                                            return <><th>-/-</th><th>-/-</th><th>-/-</th></>
                                          })}
                                        </tr>
                                        {record.activities && record.activities.map((record: any) => {
                                          return <>
                                            <tr key={record.id + '_upload'}>
                                              <td className="txt-right">Activity</td>
                                              <td>{record.code}</td>
                                              <td className="txt-right"><a href={`/programs/${record.id}`}>{record.name}</a></td>
                                              <td>
                                                {record.indicators && record.indicators.length > 0 ? <>
                                                  {record.indicators[0].name}
                                                </> : <></>}
                                              </td>
                                              <td></td>
                                              {plan.windows && plan.windows.map((w: any) => {
                                                return <><th>-/-</th><th>-/-</th><th>-/-</th></>
                                              })}
                                            </tr>
                                          </>
                                        })}
                                      </>
                                    })}
                                  </>
                                })}
                              </>
                            })}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProgramDetails;
