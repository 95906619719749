import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'client', text: 'Client'},
  {value: 'implementor', text: 'Implementor'}
]

export const ProjectFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 4}, position: 1 },
  { name: "startDate", type: "date", label: "Start Date", size: {lg: 4}, position: 2 },
  { name: "endDate", type: "date", label: "End Date", size: {lg: 4}, position: 3 },
  { name: "details", type: "textarea", label: "Details", size: {lg: 12}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);