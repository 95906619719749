import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { amountToCurrency, formattedDate, fullUrl } from "../../utils/Helpers";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProjectFields } from "../../fields/ProjectFields";
import BulkUpload from "./upload";
import { ToolFields } from "../../fields/ToolFields";


const request = new ApiCore();

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});

  const [tools, setTools] = useState<any>([]);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);

  useEffect(() => {
    loadProject();
  }, []);

  const loadProject = () => {
    request
      .get(`${Endpoints.partners}/projects/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setProject(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpdate = (item: any) => {
    loadProject();
  };

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/upload`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      loadProject();
    });
  }

  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }

  return (
    <React.Fragment>
      {project && (
        <>
          <div className="page-content">
            <ToastContainer />
            <XModal title={"Project"}
              width={"lg"}
              open={showDialog}
              toggle={() => setShowDialog(!showDialog)}
              onClose={() => setShowDialog(false)}>

              <CreateForm
                fields={ProjectFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.partners + "/projects"}
                submitButtonLabel={"Save"}
                debug={false}
                handleUpdate={handleUpdate}
                onClose={() => setShowDialog(false)} />

            </XModal>
            <XModal title={"Add a tool"}
              width={"sm"}
              open={showToolDialog}
              toggle={() => setShowToolDialog(!showToolDialog)}
              onClose={() => setShowToolDialog(false)}>

              <CreateForm
                fields={ToolFields}
                initialValues={initialValues}
                submitEndpoint={Endpoints.partners + "/projects/" + project.id + "/tool"}
                debug={false}
                handleUpdate={handleUpdate}
                handleBeforeSubmit={handleBeforeSubmit}
                onClose={() => setShowToolDialog(false)} />

            </XModal>

            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: project.id, entityType: "project" }} />

            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Project Details
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(project); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowToolDialog(true)}>
                            Add Tool
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowUploadDialog(true)}>
                            Upload documents
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              <div className="dt-value">{project.name}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-value"><i className="fa fa-calendar green"></i> {project.startDate ? formattedDate(project.startDate) : project.startDate}</div>
                            </Col>
                            <Col md={3}>
                              <div className="dt-value"><i className="fa fa-calendar red"></i> {project.endDate ? formattedDate(project.endDate) : project.endDate}</div>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col md={12}>
                              <div className="dt-value">{project.details}</div>
                            </Col>
                          </Row>
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody>
                      <b>DATA TOOLS</b><br />
                      <table className="table bordered">
                        <thead>
                          <tr><th>Name</th><th>Category</th></tr>
                        </thead>
                        <tbody>
                          {project.tools.map((record: any) => {
                            return <tr key={record.id + '_upload'}>
                              <td className="txt-right"><a href={`/tools/${record.id}`}>{record.name}</a></td>
                              <td>
                                {record.category ? record.category.name : ""}
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProjectDetails;
