interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    businessCategory?:string;
    subItems ?: any;
    isHeader ?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Analytics",
        isHeader : true
    },
    {
        id: 139,
        label: "Dashboard",
        icon: "chart-line",
        link: "/dashboard"
    },{
        id: 3,
        label: "Data Collection",
        isHeader : true
    },
    {
        id: 141,
        label: "Categories",
        icon: "arrow-alt-circle-right",
        link: "/categories"
    },
    {
        id: 141,
        label: "Tools",
        icon: "arrow-alt-circle-right",
        link: "/tools"
    },
    {
        id: 1,
        label: "Settings",
        isHeader : true
    },
    {
        id: 138,
        label: "Partners",
        icon: "users",
        link: "/partners"
    },
    {
        id: 140,
        label: "Users",
        icon: "th",
        link: "/users"
    }
];

export { menuItems };
