import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/common/Breadcrumb";
import "../../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../../helpers/api_helper";
import { Loading } from "../../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../../components/common/XModal";
import CreateForm from "../../../components/forms/CreateForm";
import { Endpoints } from "../../../utils/Endpoints";
import { ToolFields } from "../../../fields/ToolFields";
import SearchRecords from "./search";
import BulkUpload from "./upload";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { amountToCurrency, can } from "../../../utils/Helpers";
import { useHistory } from "react-router-dom";

const Tools = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [tools, setTools] = useState<any>([]);
  const [showToolDialog, setShowToolDialog] = useState<boolean>(false);
  const [showToolBulkDialog, setShowToolBulkDialog] = useState<boolean>(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    active: 1
  });

  const tableRowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      history.push("/tools/" + row.id);
    }
 }

  const columns = [
    {
      dataField: "category",
      text: "Type",
      formatter: (cellContent: any, item: any) => <span>{item.category ? item.category.name : ""}</span>
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (celllContent: any, item: any) => <span><a href={`/tools/${item.id}`}>{item.name}</a></span>
    },
    {
      dataField: "active",
      text: "Active",
      formatter: (cellContent: any, item: any) => <span>{item.active}</span>
    },
    {
      dataField: "id",
      text: "",
      formatter: (celllContent: any, item: any) => <span><a href="#" onClick={() => handleOption(item, "edit")} className="btn btn-edit"><i className="fa fa-edit"></i>Edit</a></span>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      item.categoryId = item.category ? item.category.id : "";
      setInitialValues(item);
      setShowToolDialog(true);
    } else if (type == "new") {
      setInitialValues({});
      setShowToolDialog(true);
    } else if (type == "details") {
      history.push("/tools/" + item.id);
    }
  }  

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: tools.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();

  useEffect(() => {
    searchTools({})
  }, []);

  const handleUpdate = (item: any) => {
    window.location.reload();
  };

  const handleBeforeSubmit = (values: any) => {
    values.category = {
      id: parseInt(values.categoryId)
    }

    delete values.categoryId;
    delete values.section;
    delete values.created;
    return values;
  }

  const searchTools = (values: any) => {
    toast.promise(request
    .get(Endpoints.tools, { params: values }, "json"), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
        console.log(response.data);
        setTools(response.data);
        setLoading(false);
        
    });
  }

  const handleBulkUpload = (values: any) => {
    const responseType = "arraybuffer";
    toast.promise(request.createFormData(Endpoints.tools, values, responseType), {
      pending: "Please wait..",
      success: {
        render({data}) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({data}) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      if (values.file) {
        //Nothing to do here
      } else if(values.excel && values.excel == 1) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `mustard-${+ new Date()}.xlsx`
        link.click()
      }
    });
  }

  const handlePromote = () => {
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tools</title>
        </MetaTags>

        <>

          <XModal title={"Add a tool"}
                  width={"sm"}
                  open={showToolDialog}
                  toggle={() => setShowToolDialog(!showToolDialog)}
                  onClose={() => setShowToolDialog(false)}>

            <CreateForm
              fields={ToolFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.tools : Endpoints.tools}
              debug={false}
              handleUpdate={handleUpdate}
              handleBeforeSubmit={handleBeforeSubmit}
              onClose={() => setShowToolDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Tools
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      {can("manage","Tool") && (
                        <>
                          <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                          Add tool
                          </DropdownItem>
                        </>
                      )}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading tools. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={tools}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col xl="12">
                                  <SearchRecords
                                    handleSearch={(values: any) => {
                                      searchTools(values);
                                    }}
                                  />
                                </Col>
                                <ToastContainer />
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table"
                                    }
                                    bordered={false}
                                  />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </>

      </div>
    </React.Fragment>
  );
};

export default Tools;