import { IField } from "../interfaces/inputs/IField";

const categories = [
  {value: 'General', text: 'General'},
  {value: 'Baseline', text: 'Baseline'},
  {value: 'Followup', text: 'Followup'}
]

export const ToolCategoryFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 4}, position: 1 },
  { name: "category", type: "select", label: "Category", options: categories, size: {lg: 4}, position: 14 },
].sort((a: IField, b: IField) => a.position - b.position);