import resolveBaseUrl from "../services";

const baseUrl = resolveBaseUrl();

const getFile = (fileName, type = "inline") => {
    return `${baseUrl}/files/${fileName}?type=${type}`;
};

export const Settings = {
    getFile
};