import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//  Inner Authentication
import LoginPage from "../pages/Authentication/LoginPage";
import CreateAccount from "../pages/Authentication/CreateAccount";
import Signout from "../pages/Authentication/Signout";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage";

//Error
import Error1 from "../pages/Error/Error1";
import Error2 from "../pages/Error/Error2";
import Error3 from "../pages/Error/Error3";
import ErrorBasic from "../pages/Error/ErrorBasic";
import ErrorCover from "../pages/Error/ErrorCover";

import Partners from "../pages/Partners";
import PartnerDetails from "../pages/Partners/detail";
import ProgramDetails from "../pages/Partners/program";
import ProjectDetails from "../pages/Partners/project";
import ProgramPlan from "../pages/Partners/plan";

import GoalDetails from "../pages/Logframe/goal";
import OutcomeDetails from "../pages/Logframe/outcome";
import OutputDetails from "../pages/Logframe/output";
import ActivityDetails from "../pages/Logframe/activity";
import IndicatorDetails from "../pages/Logframe/indicator";
import AssumptionDetails from "../pages/Logframe/assumption";
import MovDetails from "../pages/Logframe/mov";

//utility
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageMaintenance from "../pages/Utility/PageMaintenance";

//Data
import Categories from "../pages/Data/Categories";
import Tools from "../pages/Data/Tools";
import ToolDetails from "../pages/Data/Tools/detail";
import DataForm from "../pages/Data/Tools/dataForm";
import ViewData from "../pages/Data/Tools/data";
import DataDashboard from "../pages/Data/Tools/dashboard";

import CreateBusiness from "../pages/Authentication/CreateBusiness";
import SelectProducts from "../pages/Authentication/SelectProducts";

import UserList from "../pages/Users/index";

import Uploads from "../pages/Uploads";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  clazz?: string,
  ability?: string
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/partners", component: Partners },
  { path: "/partners/:id", component: PartnerDetails },
  { path: "/programs/:id", component: ProgramDetails },
  { path: "/plan/:id", component: ProgramPlan },
  { path: "/projects/:id", component: ProjectDetails },

  //Log frame
  { path: "/logframe/goals/:id", component: GoalDetails },
  { path: "/logframe/outcomes/:id", component: OutcomeDetails },
  { path: "/logframe/outputs/:id", component: OutputDetails },
  { path: "/logframe/activities/:id", component: ActivityDetails },
  { path: "/logframe/indicators/:id", component: IndicatorDetails },
  { path: "/logframe/assumptions/:id", component: AssumptionDetails },
  { path: "/logframe/movs/:id", component: MovDetails },


  { path: "/uploads", component: Uploads },
  { path: "/users", component: UserList, clazz: "User", ability: "manage" },

  { path: "/categories", component: Categories, clazz: "Category", ability: "manage" },
  { path: "/tools", component: Tools, clazz: "Tool", ability: "manage" },
  { path: "/tools/:id", component: ToolDetails, clazz: "Tool", ability: "manage" },
  { path: "/tools/:id/data", component: DataForm, clazz: "Tool", ability: "manage" },
  { path: "/tools/:id/viewdata", component: ViewData, clazz: "Tool", ability: "manage" },
  { path: "/tools/:id/dashboard", component: DataDashboard, clazz: "Tool", ability: "manage" },


  { path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: LoginPage },
  { path: "/logout", component: Signout },
  { path: "/register", component: LoginPage },
  { path: "/register/business", component: LoginPage },
  { path: "/register/business/products", component: LoginPage },
  { path: "/auth/password/reset", component: LoginPage },

  { path: "/error-404-basic", component: Error1 },
  { path: "/error-404-cover", component: Error2 },
  { path: "/error-403-basic", component: Error3 },
  { path: "/error-500-basic", component: ErrorBasic },
  { path: "/error-500-cover", component: ErrorCover },

  //utility page
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-maintenance", component: PageMaintenance }
];

export { userRoutes, authRoutes };
