import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Button, Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { Loading } from "../../../components/common/Loading";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Rectangle, Cell, PieChart, Pie } from 'recharts';

const request = new ApiCore();

const DataDashboard = () => {
  const { id } = useParams();
  const [tool, setTool] = useState<any>(null);
  const [fields, setFields] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});

  const graphData = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const pieData = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index } : {cx: number, cy: number, midAngle: any, innerRadius: any, outerRadius: any, percent: any, index: any}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    loadTool();
    loadData()
  }, []);

  const loadTool = () => {
    setLoading(true);
    request
      .get(`${Endpoints.tools}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setTool(data);

        let allFields: any[] = [];
        data.sections.forEach((element: any) => {
          element.fields.forEach((f: any) => {
            allFields.push(f)
          })
        });
        setFields(allFields);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loadData = () => {
    setLoadingData(true);
    request
      .get(`${Endpoints.tools}/${id}/data`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setAllData(data);
        setLoadingData(false);
        console.log(allData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      {tool && (
        <>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        {tool.name} - Dashboard
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href={`/tools/${tool.id}`}>
                            Edit
                          </DropdownItem>
                          <DropdownItem href={`/tools/${tool.id}/data`}>
                            Fill Form
                          </DropdownItem>
                          <DropdownItem href={`/tools/${tool.id}/viewdata`}>
                            View Data
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              {loadingData && <Loading label="Loading data. Please wait." />}
              {!loadingData &&
                <>
                  <LineChart width={500} height={300} data={graphData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="pv"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  </LineChart>

                  <BarChart
                    width={500}
                    height={300}
                    data={graphData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="pv" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                    <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                  </BarChart>
                  <PieChart width={400} height={400}>
                  <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {pieData.map((entry: any, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
                </>
              }
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default DataDashboard;
