import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, CardHeader, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Spinner } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiCore } from "../../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../../utils/Endpoints";

//Import React Router
import { useParams } from "react-router-dom";
import XModal from "../../../components/common/XModal";
import CreateForm from "../../../components/forms/CreateForm";
import { ToolFields } from "../../../fields/ToolFields";
import BulkUpload from "./upload";
import { SaleUpdateFields } from "../../../fields/SaleUpdateFields";
import { SectionFields } from "../../../fields/SectionFields";
import { SectionFieldFields } from "../../../fields/SectionFieldFields";
import { Form, Formik } from "formik";
import { XSelect } from "../../../components/common/inputs/XSelect";
import { XTextBox } from "../../../components/common/inputs/XTextBox";
import { XTextArea } from "../../../components/common/inputs/XTextArea";
import { XDatePickerField } from "../../../components/common/inputs/XDatePickerField";
import { XSearchableSelect } from "../../../components/common/inputs/XSearchableSelect";
import { XCheckbox } from "../../../components/common/inputs/XCheckbox";
import { ISelectOption } from "../../../interfaces/inputs/ISelectOption";
import { XRenderField } from "./XRenderField";

const request = new ApiCore();

const DataForm = () => {
  const { id } = useParams();
  const [tool, setTool] = useState<any>(null);
  const [programWindow, setProgramWindow] = useState<any>(null);
  const [fields, setFields] = useState<any>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showWindowDialog, setShowWindowDialog] = useState<boolean>(false);
  const [showMessageDialog, setShowMessageDialog] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [showFieldDialog, setShowFieldDialog] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<any>({});
  const [initialFieldValues, setInitialFieldValues] = useState<any>({});

  const [headers, setHeaders] = useState<any>([]);
  const [upload, setUpload] = useState<any>({});

  useEffect(() => {
    loadTool();
  }, []);

  const loadTool = () => {
    request
      .get(`${Endpoints.tools}/${id}`, {})
      .then((response: AxiosResponse) => {
        const { data } = response;
        setTool(data);

        let allFields: { value: any; label: any; }[] = [];
        data.sections.forEach((element: any) => {
          element.fields.forEach((f: any) => {
            allFields.push({
              value: f.ld,
              label: f.label
            })
          })
        });
        setFields(allFields);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpload = (values: any) => {
    const responseType = "json";
    toast.promise(request.createFormData(`/uploadexcel`, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      const { data } = response;
      if (data) {
        setShowUploadDialog(false);
        setHeaders(data.header);
        setUpload(data.upload);
        setShowFieldDialog(true);
      }
      setInitialValues({});
    });
  }

  const getFieldList = () => {
    let list: ISelectOption[] = []
    tool.sections.forEach((s: any) => {
      s.fields.forEach((f: any) => {
        list.push({ value: f.id, text: f.label });
      })
    })
    return list;
  }

  const handleSubmitField = (values: any) => {
    const request_body = Object.fromEntries(
      Object.entries(values.field).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.join(",") : String(value)
      ])
    );
    
    if (programWindow) {
      request_body.program_window_id = programWindow.id;
    }

    setLoading(true);
    toast.promise(request.create(Endpoints.tools + "/" + tool.id + "/data", { params: request_body }), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          setInitialValues({})
          let message: any = "";
          return message;
        }
      }
    }).then((response: AxiosResponse) => {
      setInitialValues({})
      setLoading(false);
    });
  };

  const handleExcelData = (values: any) => {
    console.log(values);
    setLoading(true);
    toast.promise(request.create(Endpoints.tools + "/" + tool.id + "/data/" + upload.id, { params: values }), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          setInitialFieldValues({});
          let message: any = "The data has been uploaded successfully";
          return message;
        }
      }
    }).then((response: AxiosResponse) => {
      setInitialFieldValues({});
      setShowFieldDialog(false);
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      {tool && (
        <>
          <div className="page-content">
            <XModal title={"Upload documents"}
              width={"lg"}
              open={showUploadDialog}
              toggle={() => setShowUploadDialog(!showUploadDialog)}
              onClose={() => setShowUploadDialog(false)}>

              <BulkUpload handleSubmit={handleUpload} initialValues={{ entityId: tool.id, entityType: "tool" }} />

            </XModal>

            <XModal title={"Monitoring Window"}
              width={"lg"}
              open={showWindowDialog}
              toggle={() => setShowWindowDialog(!showWindowDialog)}
              onClose={() => setShowWindowDialog(false)}>
              <ul className="list-group">
                {tool.windows && tool.windows.map((record: any) => {
                return <li className="list-group-item" onClick={() => {setProgramWindow(record); setShowWindowDialog(false)}}>{record.label}</li>
              })}
              </ul>

            </XModal>

            <XModal title={"Data Mappings"}
              width={"lg"}
              open={showFieldDialog}
              toggle={() => setShowFieldDialog(!showFieldDialog)}
              onClose={() => setShowFieldDialog(false)}>
              <div className="modal-body">
                <Formik
                  initialValues={initialFieldValues}
                  enableReinitialize
                  validateOnBlur
                  onSubmit={(values: any) => handleExcelData(values)}
                >
                  {({
                    isSubmitting,
                    setFieldValue,
                    values,
                    errors,
                    touched,
                  }) => (
                    <>
                      <Form>
                        <Row>
                          <Col lg={4}>
                            <b>Excel Column</b>
                          </Col>
                          <Col lg={4}>
                            <b>Data field</b>
                          </Col>
                        </Row>
                        <hr />
                        {headers.map((header: string, index: number) => {
                          return <Row key={`dt${index}`}>
                            <Col lg={4}>
                              <b>{header}</b>
                            </Col>
                            <Col lg={4}>
                              <XSelect
                                key={`p${index}`}
                                id={`p${index}`}
                                name={`p${index}`}
                                options={getFieldList()}
                                touched={touched}
                                errors={errors}
                              />
                            </Col>
                          </Row>
                        })}

                        <div className="modal-footer">
                          <Button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            {loading ? <Spinner size={"sm"} data-testid="loader"></Spinner> : "Save"}
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </XModal>

            <Container fluid>
              <Row>
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardHeader className="justify-content-between d-flex align-items-center">
                      <CardTitle className="h4">
                        Collect Data
                      </CardTitle>

                      <UncontrolledDropdown className="mt-4 mt-sm-0">
                        <DropdownToggle tag="a" className="btn btn-light">
                          <i className="fa fa-ellipsis-v"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem to="#" onClick={() => { setInitialValues(tool); setShowDialog(true); }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowMessageDialog(true)}>
                            Fill Form
                          </DropdownItem>
                          <DropdownItem href={`/tools/${tool.id}/viewdata`}>
                            View Data
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => setShowUploadDialog(true)}>
                            Upload Excel
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={10}>
                          <Row>
                            <Col md={6}>
                              <div className="dt-value">{tool.name}</div>
                            </Col>
                            <Col md={6}>
                              <div className="dt-value">
                                <a href="#" onClick={() => { setShowWindowDialog(true) }}>
                                  <i className="fa fa-edit"></i> {programWindow ? programWindow.label : "SelectMonitoring Window"}</a>
                              </div>
                            </Col>
                          </Row>
                          <hr />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <br />
            <Container fluid>
              <ToastContainer />
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur
                onSubmit={(values: any) => handleSubmitField(values)}
              >
                {({
                  isSubmitting,
                  setFieldValue,
                  values,
                  errors,
                  handleChange,
                  touched,
                }) => (
                  <>
                    <Form>
                      <Row>
                        <Col md={12} lg={12} xl={12}>
                          {
                            tool.sections.map((section: any) => {
                              return (
                                <Card key={`tool${section.id}`}>
                                  <CardHeader className="justify-content-between d-flex align-items-center">
                                    <CardTitle className="h4">
                                      {section.label}
                                    </CardTitle>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="list-group">
                                      {
                                        section.fields.map((field: any) => {
                                          return (
                                            <div className="list-group-item list-group-item-action flex-column align-items-start" key={field.id}>
                                              <div className="d-flex w-100">
                                                <XRenderField field={field} touched={touched} setFieldValue={setFieldValue} values={values} onChange={handleChange} />
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </CardBody>
                                </Card>
                              )
                            })
                          }

                        </Col>
                      </Row>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? <Spinner size={"sm"} data-testid="loader"></Spinner> : "Save"}
                      </Button>
                    </Form>
                  </>
                )}
              </Formik>
            </Container>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default DataForm;
