export const amountToCurrency = (amount: number) => {
  return amount.toLocaleString("en-US");
};

export const formattedDate = (dateStr: string) => {
  if (dateStr !== undefined && dateStr != null && dateStr.length >= 10) {
    let date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } else {
    return dateStr;
  }
};

export const tsToDateTime = (timestamp: number) => {
  var date = new Date(timestamp * 1000);

  // Get day, month, year, hours and minutes
  var day = date.getDate();
  var month = date.getMonth() + 1; // Month starts from 0
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Format the date and time
  var formattedDateTime = (day < 10 ? "0":"") + day + '/' + (month < 10 ? "0":"") + month + '/' + year + ' ' + (hours < 10 ? "0":"") + hours + ':' + (minutes < 10 ? "0":"") + minutes;

  return formattedDateTime;
}

export const dateToDbFormat = (date: Date) => {
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const currentDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const invoiceStartDate = () => {

  var today = new Date();
  var date= new Date(today.getFullYear(), today.getMonth()+1, 0);
  
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-01';
};


export const invoiceEndDate = () => {

  var today = new Date();
  var date= new Date(today.getFullYear(), today.getMonth()+1, 0);
  
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const saleOrderStartDate = () => {

  var today = new Date();
  var date= new Date(today.getFullYear(), today.getMonth()+1, 0);
  
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-01';
};


export const saleOrderEndDate = () => {

  var today = new Date();
  var date= new Date(today.getFullYear(), today.getMonth()+1, 0);
  
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  
  return yyyy + '-' + mm + '-' + dd;
};

export const getForms = () => {
  return [
    {value: 'Kinder', text: 'Kinder'},
    {value: 'Nursery', text: 'Nursery'},
    {value: 'Pre-primary', text: 'Pre-primary'},
    {value: 'P1', text: 'P1'},
    {value: 'P2', text: 'P2'},
    {value: 'P3', text: 'P3'},
    {value: 'P4', text: 'P4'},
    {value: 'P5', text: 'P5'},
    {value: 'P6', text: 'P6'},
    {value: 'P7', text: 'P7'},
  ]
}

export const setCurrentTenant = (tenant: any) => {
  localStorage.setItem("__strive__tenant__", JSON.stringify({
    id: tenant.id,
    tenantcode: tenant.tenantcode,
    name: tenant.name,
    logo: tenant.logo_path,
    logo_path: tenant.logo_path,
    category: tenant.category,
    schooltype: tenant.schooltype
  }))
}

export const setCurrentProgram = (tenant: any) => {
  localStorage.setItem("__strive__program__", JSON.stringify({
    id: tenant.id,
    name: tenant.name,
    status: tenant.status
  }))
}

export const currentTenant = () => {
  const current_user = localStorage.getItem("__strive__user__");
  const current_tenant = localStorage.getItem("__strive__tenant__");
  var tenant = { name: "-/-", logo: "-/-", phone: "-/-", email: "-/-", logo_path: "-/-", id: 0, category: "---", tenantcode: "---", schooltype: "primary" };
  if (current_user) {
    const user = JSON.parse(current_user);
    if (current_tenant) {
      tenant = JSON.parse(current_tenant);
    } else {
      if (user.tenants.length > 0) {
        tenant = user.tenants[0];
      }
    }
  }
  return tenant;
};

export const currentProgram = () => {
  const current_user = localStorage.getItem("__strive__user__");
  const current_tenant = localStorage.getItem("__strive__program__");
  var tenant = { name: "-/-", logo: "-/-", phone: "-/-", email: "-/-", logo_path: "-/-", id: 0, category: "---", tenantcode: "---", status: "IN PROGRESS" };
  if (current_user) {
    const user = JSON.parse(current_user);
    if (current_tenant) {
      tenant = JSON.parse(current_tenant);
    } else {
      if (user.programs.length > 0) {
        tenant = user.programs[0];
      }
    }
  }
  return tenant;
};

export const allTenants = () => {
  let tenants: any = [];
  const current_user = localStorage.getItem("__strive__user__");
  if (current_user) {
    const user = JSON.parse(current_user);
    tenants = user.tenants;
  }
  return tenants;
} 

export const allPrograms = () => {
  let tenants: any = [];
  const current_user = localStorage.getItem("__strive__user__");
  if (current_user) {
    const user = JSON.parse(current_user);
    tenants = user.programs;
  }
  return tenants;
} 

export const fullUrl = (url: string) => {
  const API_URL = "https://api.credify.africa";
  if (url && !url.startsWith('http')) {
    url = API_URL + url;
  }
  return url;
};

export const generateRandomColors = (limit: number) => {
  const list: string[] = [];
  for(let i = 0; i < limit; i++) {
    var randomColor = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
    list.push(randomColor);
  }
  return list;
}

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}; 

export const isLoggedIn = () => {
  const token = localStorage.getItem("__strive__access__token__");
  let result = false;
  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 > Date.now()) {
      result = true;
    }
  }

  if (!result) {
    localStorage.removeItem("__strive__access__token__");
    localStorage.removeItem("__strive__user__");
  }

  return result;
}

export const isAdmin = () => {
  let result = false;
  
  if (isLoggedIn() && localStorage.getItem("roles") !== null) {
    const current_user = localStorage.getItem("__strive__user__");
    if (current_user) {
      const user = JSON.parse(current_user);
      const roles = user.roles;
      result = (roles.includes("ADMIN") || roles.includes("ROLE_ADMIN"));
    }
  }

  return true;
};

export   const round = (num: any) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const taxCalculator = (item: any) => {
  let tax = 0;
  if (item.tax) {
    tax = item.amount - (item.amount/(1 + (item.tax.taxpct / 100)));
    tax = round(tax);
  }
  return tax;
}


export const getStorableUser = (data: any) => {
  const permissions: any = {};
  let is_admin = null;

  if (data.is_admin || data.admin) {
    is_admin = true
  }

  const user = {
    id: data.id,
    name: data.user.name,
    is_admin: is_admin,
    admin: is_admin,
    email: data.user.email,
    permissions: permissions,
    tenants: data.partners.map((tenant: any) => ({
      id: tenant.id,
      email: tenant.email,
      name: tenant.name,
      logo: tenant.logo,
      logo_path: tenant.logo ? tenant.logo.path : "",
      category: tenant.category,
      sector: tenant.sector
    })),
    programs: data.programs.map((record: any) => ({
      id: record.id,
      name: record.name,
      status: record.status
    }))
  }

  return user;
}

export const can = (action: string, clazz: string, object: any = null) => {
  return isAdmin();
}

export const getIndicatorClass = (record: any) => {
  var cls = "indicator-gray";
  if (record.actual == 0) {
    cls = "indicator-gray";
  } else if (record.actual < record.target) {
    cls = "indicator-red";
  } else if (record.actual > record.target) {
    cls = "indicator-blue";
  }  else if (record.actual == record.target) {
    cls = "indicator-green";
  }
  return "indicator-card " + cls;
}


