import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosError, AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { UserFields } from "../../fields/UserFields";
import SearchRecords from "./search";
import BulkUpload from "./upload";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { can } from "../../utils/Helpers";

const Users = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    phone: "",
    email: ""
  });

  const columns = [
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "phone",
      text: "Phone"
    },
    {
      dataField: "email",
      text: "Email"
    },
    {
      dataField: "",
      text: "",
      formatter: (cellContent: any, item: any) => <span><a href="#" onClick={() => handleOption(item, "edit")} className="btn btn-edit"><i className="fa fa-edit"></i> Edit</a></span>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowUserDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        phone: "",
        role: "",
        email: "",
        password: ""
      });
      setShowUserDialog(true);
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: users.length,
    custom: true
  };

  const request = new ApiCore();

  useEffect(() => {
    searchUsers({})
  }, []);

  const handleUpdate = (item: any) => {
    setLoading(false);
    window.location.reload();
  };

  const onSubmit = (values: any) => {
    setLoading(true);
    values.role = [values.role];
    if (values.id) {
      let newValues = {
        id: values.id,
        name: values.name,
        email: values.email,
        phone: values.phone,
        password: values.password
      }

      request.update(Endpoints.users, newValues)
        .then((response: AxiosResponse) => {
          handleUpdate(response.data);
        }).catch((error: AxiosError) => {
          console.log(error)
        })
    } else {
      request.create(Endpoints.users, values)
        .then((response: AxiosResponse) => {
          handleUpdate(response.data);
        }).catch((error: AxiosError) => {
          console.log(error)
        })
    }
  };

  const searchUsers = (values: any) => {
    setLoading(true);
    toast.promise(request
      .get(Endpoints.users, { params: values }, "json"), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      const { users, totalRecords } = response.data;
      setUsers(users);
      setLoading(false);

    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users - Datafactory</title>
        </MetaTags>

        <>

          <XModal title={"Add a user"}
            width={"lg"}
            open={showUserDialog}
            toggle={() => setShowUserDialog(!showUserDialog)}
            onClose={() => setShowUserDialog(false)}>

            <CreateForm
              fields={UserFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.users + "/" + initialValues.id : Endpoints.users}
              submitButtonLabel={initialValues.id ? "Edit User" : "Add User"}
              debug={false}
              handleUpdate={handleUpdate}
              handleSubmit={onSubmit}
              onClose={() => setShowUserDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Users
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      {can("manage", "User") && (
                        <>
                          <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                            Add user
                          </DropdownItem>
                        </>
                      )}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading users. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={users}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col xl="12">
                                  <SearchRecords
                                    handleSearch={(values: any) => {
                                      searchUsers(values);
                                    }}
                                  />
                                </Col>
                                <ToastContainer />
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </>

      </div>
    </React.Fragment>
  );
};

export default Users;